"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipmentFeeTypeDataGrid = void 0;
const ux_1 = require("@truxweb/ux");
const schemas_1 = require("@truxweb/schemas");
const react_1 = __importStar(require("react"));
const tableColumns_1 = require("../../tableColumns");
const quote_utils_1 = require("@truxweb/quote-utils");
const ShipmentFeeTypeDataGrid_styles_1 = require("./ShipmentFeeTypeDataGrid.styles");
const hooks_1 = require("@truxweb/hooks");
const DEFAULT_ROW_HEIGHT = 96;
const ShipmentFeeTypeDataGrid = ({ columnKeys, currentSort, data, defaultSort, handleAddNewShipmentFeeType, handleColumnVisibilityChange, handleFilterChange, handleRouteToFeeType, handleSortingChange, isLazyLoading, isLoading, language, lastLoadedDate, onPageChange, onPageSizeChange, pageSize, paginationMode, rowHeight, shouldAutoRefresh, shouldDisableVirtualization, sortingMode, t, }) => {
    const classes = (0, ShipmentFeeTypeDataGrid_styles_1.useStyles)();
    const tableRowHeight = rowHeight || DEFAULT_ROW_HEIGHT;
    const dynamicPageSize = (0, hooks_1.useTableRowCount)(ux_1.useMediaQuery, {
        lg: '1524px',
        xlg: '1824px',
    });
    pageSize = dynamicPageSize || pageSize;
    const [displayCols] = (0, react_1.useMemo)(() => {
        const basicColumnDef = {
            classes,
            fallbackText: t('common:na'),
            t,
        };
        const columns = [
            {
                field: 'isActive',
                flex: 1,
                headerName: t('common:isActive'),
                type: 'boolean',
            },
            (0, tableColumns_1.simpleTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'feeType', headerName: t('common:feeType'), type: 'boolean', width: 200 })),
            (0, tableColumns_1.simpleTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'defaultFeeValueType', flex: 1, getTextValue: (row) => {
                    return t(`common:${row.defaultFeeValueType}`);
                }, headerName: t('common:defaultFeeValueType'), type: 'singleSelect', valueOptions: [...Object.values(schemas_1.EPriceTypeV1).map((value) => t(`common:${value}`))], width: 150 })),
            (0, tableColumns_1.simpleTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'defaultFeeValue', flex: 1, headerName: t('common:defaultFeeValue'), width: 50 })),
            (0, tableColumns_1.simpleTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'feeLabelEn', flex: 1, headerName: t('common:EN_CA'), width: 150 })),
            (0, tableColumns_1.simpleTextColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'feeLabelFr', flex: 1, headerName: t('common:FR_CA'), width: 150 })),
            {
                field: 'isVisibleByDefault',
                flex: 1,
                headerName: t('common:isVisibleByDefault'),
                type: 'boolean',
            },
            (0, tableColumns_1.simpleDateColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'dateCreated', flex: 1, headerName: t('common:dateCreated'), width: 150 })),
            (0, tableColumns_1.simpleDateColumn)(Object.assign(Object.assign({}, basicColumnDef), { field: 'dateUpdated', flex: 1, headerName: t('common:dateUpdated'), width: 150 })),
        ];
        const fullColumns = columns
            .filter((col) => Boolean(col))
            .map((col) => {
            return Object.assign(Object.assign({}, col), { description: col === null || col === void 0 ? void 0 : col.headerName });
        });
        const displayCols = fullColumns.map((col) => {
            if (col.disableColumnMenu)
                return col;
            if (!columnKeys) {
                return Object.assign(Object.assign({}, col), { hide: false });
            }
            return Object.assign(Object.assign({}, col), { hide: !columnKeys.includes(col.field) });
        });
        return [displayCols];
    }, [columnKeys, classes, t]);
    const toolbar = () => {
        return (react_1.default.createElement(ux_1.DashboardDataGridToolbar, { actions: react_1.default.createElement(ux_1.Box, { mr: 5 },
                react_1.default.createElement(ux_1.FlatButton, { color: "primaryLight", "data-testid": 'SHIPMENT_FEE_TYPE_DATA_GRID-AddNewShipmentFee', onClick: handleAddNewShipmentFeeType, variant: "contained" }, t('common:addNewShipmentFee'))), areFiltersEnabled: true, isExportEnabled: true, isHeaderShown: true, isLazyLoading: isLazyLoading, isLoading: isLoading, language: language, lastLoadedDate: lastLoadedDate, rowCount: (data === null || data === void 0 ? void 0 : data.length) || 0, shouldAutoRefresh: shouldAutoRefresh, t: t, testId: "SHIPMENT_FEE_TYPE_DATA_GRID" }));
    };
    return (react_1.default.createElement(ux_1.DashboardDataGrid, { columns: displayCols, currentSort: currentSort, data: data || [], defaultSort: defaultSort, gridHeight: ((pageSize || 10) + 2.15) * tableRowHeight, handleCellClick: ({ row }) => {
            handleRouteToFeeType(row.id);
        }, handleColumnVisibilityChange: handleColumnVisibilityChange, handleFilterChange: handleFilterChange, handleTotalRowDisplayText: quote_utils_1.getTotalGridRowsText, hasCustomHeader: true, isLoading: isLoading, onPageChange: onPageChange, onPageSizeChange: onPageSizeChange, onSortChange: handleSortingChange, pageSize: pageSize, paginationMode: paginationMode, rowHeight: tableRowHeight, shouldDisableMultipleSelection: true, shouldDisableVirtualization: shouldDisableVirtualization, shouldHideFooterSelectedRowCount: true, sortingMode: sortingMode, t: t, toolbar: toolbar }));
};
exports.ShipmentFeeTypeDataGrid = ShipmentFeeTypeDataGrid;

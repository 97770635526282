import {
  EShipmentLocationTypeV1,
  EShipmentQuoteRequestStatusV1,
  EShipmentStatusGroupV1,
  EShipmentStatusV1,
} from '@truxweb/schemas';
import { createSlice } from '@reduxjs/toolkit';
import { getStatusGroup } from '@truxweb/shipment-utils';
import { HYDRATE } from 'next-redux-wrapper';

export type TApplicationLoadingState = {
  staticText?: string;
  loadingPhrases?: string[];
  isApplicationLoading: boolean;
  isActionLoading: boolean;
  actionId?: number;
  actionType?: string;
};

type TCreatedCompanyLocation = Record<EShipmentLocationTypeV1, boolean>;

export interface IUXState {
  activeMenuItem: string;
  applicationLoadingState: TApplicationLoadingState;
  shipmentTableGroup: EShipmentStatusGroupV1[];
  shipmentTableStatuses: EShipmentStatusV1[];
  sidebarState: boolean;
  createdQuoteCompanyLocations: TCreatedCompanyLocation;
  quoteTableStatuses: EShipmentQuoteRequestStatusV1[];
  isAddLoadSidebarOpen: boolean;
  isShipperSubscriptionBannerShown?: boolean;
}

export const uxSlice = createSlice({
  extraReducers: {
    [HYDRATE]: (state, action) => {
      if (state) return state;
      return {
        ...state,
        ...action.payload.ux,
      };
    },
  },
  initialState: {
    activeMenuItem: '',
    applicationLoadingState: {
      isActionLoading: false,
      isApplicationLoading: false,
    },
    createdQuoteCompanyLocations: {
      [EShipmentLocationTypeV1.DESTINATION]: false,
      [EShipmentLocationTypeV1.ORIGIN]: false,
    },
    isAddLoadSidebarOpen: false,
    isShipperSubscriptionBannerShown: undefined,
    quoteTableStatuses: [
      EShipmentQuoteRequestStatusV1.PENDING,
      EShipmentQuoteRequestStatusV1.MODIFIED,
    ],
    shipmentTableGroup: [EShipmentStatusGroupV1.UPCOMING, EShipmentStatusGroupV1.IN_PROGRESS],
    shipmentTableStatuses: [
      ...getStatusGroup(EShipmentStatusGroupV1.UPCOMING),
      ...getStatusGroup(EShipmentStatusGroupV1.IN_PROGRESS),
    ],
    sidebarState: false,
  } as IUXState,
  name: 'ux',
  reducers: {
    handleToggleQuoteTableStatues: (state, { payload: { forceToggle, statuses } }) => {
      if (forceToggle) {
        return {
          ...state,
          quoteTableStatuses: statuses,
        };
      }
      const newCarrierQuoteStatuses = [...state.quoteTableStatuses];

      statuses.forEach((status: EShipmentQuoteRequestStatusV1) => {
        const groupIndex = newCarrierQuoteStatuses.indexOf(status);
        if (groupIndex > -1) {
          newCarrierQuoteStatuses.splice(groupIndex, 1);
        } else {
          newCarrierQuoteStatuses.push(status);
        }
      });

      return {
        ...state,
        quoteTableStatuses: newCarrierQuoteStatuses,
      };
    },
    handleToggleShipmentTableGroup: (
      state,
      { payload: { forceToggle, forceToggleSet, statusGroups } }
    ) => {
      const newTableGroups = [...state.shipmentTableGroup];

      if (forceToggle && !forceToggleSet) {
        return {
          ...state,
          shipmentTableGroup: statusGroups,
          shipmentTableStatuses: [...getStatusGroup(statusGroups[0], true)],
        };
      }

      if (!forceToggle && forceToggleSet) {
        const forceShipmentStatues = [];
        statusGroups.forEach((group: EShipmentStatusGroupV1) => {
          forceShipmentStatues.push(...getStatusGroup(group, true));
        });
        return {
          ...state,
          shipmentTableGroup: statusGroups,
          shipmentTableStatuses: forceShipmentStatues,
        };
      }

      statusGroups.forEach((statusGroup: EShipmentStatusGroupV1) => {
        const groupIndex = newTableGroups.indexOf(statusGroup);
        if (groupIndex > -1) {
          newTableGroups.splice(groupIndex, 1);
        } else {
          newTableGroups.push(statusGroup);
        }
      });

      const newShipmentTableStatus = [];
      newTableGroups.forEach((group) => {
        newShipmentTableStatus.push(...getStatusGroup(group, true));
      });

      return {
        ...state,
        shipmentTableGroup: newTableGroups,
        shipmentTableStatuses: newShipmentTableStatus,
      };
    },
    handleToggleSubscriptionBanner: (state, { payload }) => {
      return {
        ...state,
        isShipperSubscriptionBannerShown: payload,
      };
    },
    updateActiveMenuItem: (state, { payload }) => {
      return { ...state, activeMenuItem: payload.activeMenuItem };
    },
    updateApplicationLoadingState: (state, { payload }) => {
      return {
        ...state,
        applicationLoadingState: { ...payload },
      };
    },
    updateQuoteCompanyLocations: (state, { payload }) => {
      return { ...state, createdQuoteCompanyLocations: payload };
    },
    updateSidebarState: (state, { payload }) => {
      return { ...state, sidebarState: payload.sidebarState };
    },
    updateUxState: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});
export const {
  actions: {
    handleToggleQuoteTableStatues,
    handleToggleShipmentTableGroup,
    handleToggleSubscriptionBanner,
    updateActiveMenuItem,
    updateApplicationLoadingState,
    updateQuoteCompanyLocations,
    updateSidebarState,
    updateUxState,
  },
} = uxSlice;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuoteRequestCarrierResponseDetails = void 0;
const quote_utils_1 = require("@truxweb/quote-utils");
const date_fns_1 = require("date-fns");
const ux_1 = require("@truxweb/ux");
const react_hook_form_1 = require("react-hook-form");
const utils_1 = require("@truxweb/utils");
const schemas_1 = require("@truxweb/schemas");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const errors_1 = require("@truxweb/errors");
const utils_2 = require("../../utils");
const next_i18next_1 = require("next-i18next");
const QuoteRequestCarrierResponseDetails_styles_1 = require("./QuoteRequestCarrierResponseDetails.styles");
const QuoteRequestCarrierResponseDetails = ({ addAlert, areEditsDisabled, children, locale, quoteData, shouldAllowMultipleEdits, t, updateQuoteRequestForCarrier, viewingUserType, }) => {
    const classes = (0, QuoteRequestCarrierResponseDetails_styles_1.useStyles)();
    const [areActionsShown, setActionsShown] = (0, react_1.useState)(false);
    const [isSaving, setIsSaving] = (0, react_1.useState)(false);
    const [isInRefusal, setInRefusal] = (0, react_1.useState)(false);
    const [hasPriceChanged, setHasPriceChanged] = (0, react_1.useState)(false);
    const [areExpirationDetailsShown, setExpirationDetailsShown] = (0, react_1.useState)(false);
    const { control, handleSubmit, register, setValue, watch } = (0, react_hook_form_1.useForm)();
    const [confirmationButtonText, setConfirmationButtonText] = (0, react_1.useState)(t('common:accept'));
    const [negationButtonText, setNegationButtonText] = (0, react_1.useState)(t('common:refuse'));
    const dateParent = (0, react_1.useRef)(null);
    const handleToggleExpirationDetails = (0, react_1.useCallback)(() => {
        setExpirationDetailsShown(!areExpirationDetailsShown);
    }, [setExpirationDetailsShown, areExpirationDetailsShown]);
    const [hasCarrierTimerExpired, hasShipperTimeExpired] = (0, react_1.useMemo)(() => {
        const carrierTimeRemaining = (0, quote_utils_1.getCarrierQuoteCountdownValueInSeconds)(quoteData, true);
        const shipperTimeRemaining = (0, quote_utils_1.getShipperQuoteCountdownValueInSeconds)([quoteData], true);
        return [carrierTimeRemaining <= 0, shipperTimeRemaining <= 0];
    }, [quoteData]);
    const quoteExpirationDate = (0, date_fns_1.format)((0, date_fns_1.add)(new Date(`${quoteData.quoteSubmissionDate}Z`), {
        minutes: quoteData.carrierResponseTimeInMinutes,
    }), utils_1.DATE_FORMAT_STRINGS_SHORT_WITH_TIMES[(0, utils_1.transformI18nLocaleToLanguage)(locale)] ||
        utils_1.DATE_FORMAT_STRINGS_SHORT_WITH_TIMES[schemas_1.ELanguageV1.EN_CA]);
    const isEditable = (0, react_1.useMemo)(() => {
        if (hasCarrierTimerExpired)
            return false;
        if (areEditsDisabled === true)
            return false;
        if (shouldAllowMultipleEdits)
            return ([
                schemas_1.EShipmentQuoteRequestStatusV1.PENDING,
                schemas_1.EShipmentQuoteRequestStatusV1.MODIFIED,
                schemas_1.EShipmentQuoteRequestStatusV1.ACCEPTED,
            ].includes(quoteData.carrierQuoteStatus) &&
                quoteData.shipperQuoteStatus === schemas_1.EShipmentQuoteRequestStatusV1.PENDING);
        return quoteData.carrierQuoteStatus === schemas_1.EShipmentQuoteRequestStatusV1.PENDING;
    }, [areEditsDisabled, quoteData, shouldAllowMultipleEdits, hasCarrierTimerExpired]);
    const synetheticCharge = (0, react_1.useMemo)(() => {
        return (0, quote_utils_1.createSyntheticQuoteCharge)(quoteData.charges, true);
    }, [quoteData]);
    const [isSaveButtonDisabled, setSaveButtonDisabled] = (0, react_1.useState)(!Boolean(synetheticCharge.charge.modified.value));
    // NOTE: Currently only a single piece of equipment is supported
    // per order, which is why we pick the first piece of equipment
    const accessorials = (0, react_1.useMemo)(() => {
        return quoteData.accessorials.slice().sort((a, b) => {
            return (b.metadata || []).length - (a.metadata || []).length;
        });
    }, [quoteData]);
    const handleUpdateQuoteCharge = (0, react_1.useCallback)((request) => {
        const { formData, key, prefix, record } = request;
        return Object.assign(Object.assign({}, record.charge), { charge: {
                modified: Object.assign(Object.assign({}, record.charge.charge.modified), { value: formData[`${prefix}-${key}`] }),
                original: Object.assign({}, record.charge.charge.original),
            } });
    }, []);
    const handleUpdateQuoteCharges = (0, react_1.useCallback)((formData) => {
        const isAllInRate = Boolean(parseInt(formData.isAllInRate));
        const updatedCharges = [];
        const { accessorialPricing, additionalCharges, equipmentPricing } = (0, utils_2.groupQuoteCharges)(quoteData, quoteData.charges, schemas_1.EUserTypeV1.CARRIER, t);
        Object.keys(equipmentPricing).forEach((key) => {
            const record = equipmentPricing[parseInt(key, 10)];
            if (record && formData[`equipmentPrice-${key}`]) {
                updatedCharges.push(handleUpdateQuoteCharge({ formData, key, prefix: 'equipmentPrice', record }));
            }
        });
        Object.keys(accessorialPricing).forEach((key) => {
            const record = accessorialPricing[parseInt(key, 10)];
            const accessorialKey = `accessorialPrice-${key}`;
            if (record &&
                (formData[accessorialKey] || (formData[accessorialKey] !== undefined && isAllInRate))) {
                // NOTE: If this is an all in price, Accessorials will be tracked as zero dollar
                updatedCharges.push(handleUpdateQuoteCharge({
                    formData,
                    key,
                    prefix: 'accessorialPrice',
                    record,
                }));
            }
        });
        Object.keys(additionalCharges).forEach((key) => {
            const record = additionalCharges[parseInt(key, 10)];
            const fscKey = `fuelSurcharge-${key}`;
            if (record &&
                (formData[fscKey] || (formData[fscKey] !== undefined && isAllInRate)) &&
                record.charge.chargeType === 'FUEL_SURCHARGE') {
                updatedCharges.push(handleUpdateQuoteCharge({ formData, key, prefix: 'fuelSurcharge', record }));
            }
        });
        const updatedIds = updatedCharges.map(({ id }) => id);
        quoteData.charges.forEach((charge) => {
            if (!updatedIds.includes(charge.id)) {
                updatedCharges.push(charge);
            }
        });
        return updatedCharges;
    }, [quoteData, t, handleUpdateQuoteCharge]);
    const onSubmitSuccess = (0, react_1.useCallback)((formData) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsSaving(true);
            const newCharges = handleUpdateQuoteCharges(formData);
            const accessorialMetadata = Object.keys(formData)
                .map((key) => {
                if (!key.includes('accessorialMetadata'))
                    return null;
                const keyParts = key.split('-');
                const accessorialCode = keyParts[1];
                const metadataType = keyParts[2];
                return {
                    accessorialCode,
                    metadataType,
                    value: formData[key],
                };
            })
                .filter((option) => Boolean(option));
            const updateQuoteRequest = {
                accessorialMetadata,
                bookingDate: Object.assign(Object.assign({}, quoteData.bookingDate), { modified: formData.bookingDate }),
                carrierQuoteRef: formData.carrierQuoteRef,
                charges: newCharges,
                deliveryDate: Object.assign(Object.assign({}, quoteData.estimatedDeliveryDate), { modified: formData.estimatedDeliveryDate }),
                fscRate: formData.fscRate,
                isAllInRate: Boolean(parseInt(formData.isAllInRate)),
                isFSCActive: Boolean(formData.fscRate),
                statusChange: formData.formAction,
                statusChangeReason: formData.carrierNote,
            };
            // If this is a refusal request we need to pull the statusChangeReason from other
            // fields
            if (updateQuoteRequest.statusChange === schemas_1.EShipmentQuoteRequestStatusV1.REFUSED) {
                updateQuoteRequest.statusChangeReason =
                    formData.refusalReason !== 'other'
                        ? formData.refusalReason
                        : formData.customRefusalReason;
            }
            yield updateQuoteRequestForCarrier({
                quoteId: quoteData.carrierRequestId,
                shouldAdjustLanePricing: formData.shouldAdjustLanePricing,
                updateQuoteRequest,
            });
            if (isInRefusal)
                setInRefusal(false);
            // Show an alert message telling the user where this quote has moved to if the status
            // is changing
            if (updateQuoteRequest.statusChange) {
                if ([
                    schemas_1.EShipmentQuoteRequestStatusV1.MODIFIED,
                    schemas_1.EShipmentQuoteRequestStatusV1.ACCEPTED,
                ].includes(updateQuoteRequest.statusChange)) {
                    addAlert({
                        message: t('common:quoteAcceptedMessage'),
                        severity: 'success',
                        title: t('common:quoteAcceptedTitle'),
                    });
                }
                else if ([schemas_1.EShipmentQuoteRequestStatusV1.REFUSED].includes(updateQuoteRequest.statusChange)) {
                    addAlert({
                        message: t('common:quoteRefusedMessage'),
                        severity: 'warning',
                        title: t('common:quoteRefusedTitle'),
                    });
                }
            }
        }
        catch (err) {
            addAlert({
                message: (0, errors_1.getErrorMessage)(err, t),
                severity: 'error',
            });
        }
        finally {
            setIsSaving(false);
        }
    }), [
        isInRefusal,
        handleUpdateQuoteCharges,
        quoteData,
        setIsSaving,
        addAlert,
        t,
        updateQuoteRequestForCarrier,
        setInRefusal,
    ]);
    const submitForm = (0, react_1.useCallback)(() => {
        handleSubmit(onSubmitSuccess)();
    }, [handleSubmit, onSubmitSuccess]);
    const handleConfirmationButton = (0, react_1.useCallback)(() => {
        if (!isInRefusal) {
            setValue('formAction', schemas_1.EShipmentQuoteRequestStatusV1.ACCEPTED);
            submitForm();
        }
        else {
            setValue('formAction', schemas_1.EShipmentQuoteRequestStatusV1.REFUSED);
            submitForm();
        }
    }, [isInRefusal, setValue, submitForm]);
    const handleNegationButton = (0, react_1.useCallback)(() => {
        if (!isInRefusal) {
            setValue('formAction', schemas_1.EShipmentQuoteRequestStatusV1.REFUSED);
            setConfirmationButtonText(t('common:declineButton'));
            setNegationButtonText(t('common:cancel'));
            setInRefusal(true);
        }
        else {
            setValue('formAction', schemas_1.EShipmentQuoteRequestStatusV1.ACCEPTED);
            setInRefusal(false);
        }
    }, [setValue, isInRefusal, setNegationButtonText, setConfirmationButtonText, t]);
    (0, react_1.useEffect)(() => {
        const subscription = watch((data) => {
            if (data.formAction === schemas_1.EShipmentQuoteRequestStatusV1.REFUSED && !isInRefusal) {
                setInRefusal(true);
            }
            else if (data.formAction === schemas_1.EShipmentQuoteRequestStatusV1.MODIFIED) {
                setConfirmationButtonText(t('common:modify'));
            }
            else if (data.formAction === schemas_1.EShipmentQuoteRequestStatusV1.ACCEPTED) {
                setConfirmationButtonText(t('common:accept'));
            }
            const numericPrice = parseFloat(data.price);
            if (data.formAction === schemas_1.EShipmentQuoteRequestStatusV1.REFUSED || numericPrice !== 0) {
                setSaveButtonDisabled(false);
            }
            else if (data.formAction !== schemas_1.EShipmentQuoteRequestStatusV1.REFUSED && numericPrice === 0) {
                setSaveButtonDisabled(true);
            }
            // check to see if the price has change
            if (numericPrice !== synetheticCharge.charge.modified.value) {
                setHasPriceChanged(true);
            }
            else {
                setHasPriceChanged(false);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, setHasPriceChanged, setConfirmationButtonText, synetheticCharge, isInRefusal, t]);
    (0, react_1.useEffect)(() => {
        if (quote_utils_1.REFUSED_QUOTE_STATUSES.includes(quoteData.carrierQuoteStatus) ||
            quote_utils_1.REFUSED_QUOTE_STATUSES.includes(quoteData.shipperQuoteStatus) ||
            (quote_utils_1.ACCEPTED_QUOTE_STATUSES.includes(quoteData.carrierQuoteStatus) &&
                quote_utils_1.ACCEPTED_QUOTE_STATUSES.includes(quoteData.shipperQuoteStatus))) {
            setActionsShown(false);
            return;
        }
        setActionsShown(true);
        return;
    }, [quoteData, setActionsShown]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        children,
        react_1.default.createElement("form", { onSubmit: submitForm },
            react_1.default.createElement(ux_1.Grid, { item: true },
                react_1.default.createElement(ux_1.Box, { pl: 4, pr: 4 },
                    react_1.default.createElement(ux_1.Grid, { container: true, direction: "column" },
                        quoteData.shipmentType === schemas_1.ETruckloadTypeV1.LTL &&
                            quoteData.carrierQuoteStatus === schemas_1.EShipmentQuoteRequestStatusV1.PENDING && (react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                            react_1.default.createElement(ux_1.Box, { mt: 2.5 },
                                react_1.default.createElement(ux_1.Typography, null, t('common:zeroDollarLTLPrompt'))))),
                        react_1.default.createElement(ux_1.Box, { mt: 2.5 },
                            react_1.default.createElement(ux_1.Grid, { container: true, item: true },
                                react_1.default.createElement(ux_1.Grid, { item: true, xs: 6 },
                                    react_1.default.createElement(ux_1.Box, { mr: 1 },
                                        react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: quoteData.bookingDate.modified, name: "bookingDate", render: ({ field: { onChange, value } }) => {
                                                const bookingDate = Object.assign(Object.assign({}, quoteData.bookingDate), { modified: value });
                                                return (react_1.default.createElement("div", { ref: dateParent },
                                                    react_1.default.createElement(__1.QuoteDetailsDate, { anchorEl: dateParent.current, date: bookingDate, icon: react_1.default.createElement(ux_1.DateRangeIcon, null), isEditable: !quoteData.isBookingDateFlexible ? false : isEditable, isLocked: !quoteData.isBookingDateFlexible, locale: locale, onChange: onChange, testId: `QuoteRequestCarrierDetails-PickUpDate`, title: quoteData.isBookingDateFlexible
                                                            ? t('common:pickUpDate')
                                                            : t('common:pickUpDateNotModifiable') })));
                                            } }))),
                                react_1.default.createElement(ux_1.Grid, { item: true, xs: 6 },
                                    react_1.default.createElement(ux_1.Box, { ml: 1 },
                                        react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: quoteData.estimatedDeliveryDate.modified, name: "estimatedDeliveryDate", render: ({ field: { onChange, value } }) => {
                                                const estimatedDeliveryDate = Object.assign(Object.assign({}, quoteData.estimatedDeliveryDate), { modified: value });
                                                return (react_1.default.createElement("div", { ref: dateParent },
                                                    react_1.default.createElement(__1.QuoteDetailsDate, { anchorEl: dateParent.current, date: estimatedDeliveryDate, icon: react_1.default.createElement(ux_1.EventAvailableIcon, null), isEditable: isEditable, locale: locale, onChange: onChange, testId: `QuoteRequestCarrierDetails-EstimatedDeliveryDate`, title: t('common:estimatedDeliveryDate') })));
                                            } }))),
                                react_1.default.createElement(ux_1.Grid, { item: true, xs: 12 },
                                    react_1.default.createElement(ux_1.Box, { mt: 2 },
                                        react_1.default.createElement("input", Object.assign({ defaultValue: 0, id: "id", type: "hidden" }, register(`isAllInRate`))),
                                        react_1.default.createElement(__1.QuoteDetailsCarrierPrice, { control: control, isEditable: isEditable, isInRefusal: isInRefusal, key: `${isInRefusal}`, locale: locale, quoteData: quoteData, setValue: setValue, t: t, watch: watch }))))),
                        react_1.default.createElement(ux_1.Grid, { item: true },
                            react_1.default.createElement(ux_1.Box, { mt: 2.5 },
                                react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: quoteData.carrierQuoteRef || '', name: 'carrierQuoteRef', render: ({ field: { onChange, value } }) => {
                                        return (react_1.default.createElement("input", { className: classes.carrierQuoteRef, disabled: !isEditable, id: "carrierQuoteRef", onChange: onChange, placeholder: viewingUserType === schemas_1.EUserTypeV1.TRUXWEB
                                                ? t('common:carrierQuoteRef')
                                                : t('common:quoteRef'), type: "string", value: value }));
                                    } })))))),
            react_1.default.createElement(ux_1.Collapse, { in: isInRefusal },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Box, { mt: 5, pl: 4, pr: 4 },
                        react_1.default.createElement(__1.QuoteRequestRefusalDetails, { control: control, localizationSelectPrefix: "CARRIER-carrierRefusalReason", sourceEnum: schemas_1.EQuoteCarrierRefusalReasonV1, t: t })))),
            react_1.default.createElement(ux_1.Collapse, { in: !isInRefusal },
                react_1.default.createElement(ux_1.Grid, { item: true },
                    react_1.default.createElement(ux_1.Box, { mt: 3, pl: 4, pr: 4 },
                        react_1.default.createElement(__1.QuoteRequestOptionalAccessorials, { accessorials: accessorials, control: control, isDisabled: quoteData.carrierQuoteStatus !== schemas_1.EShipmentQuoteRequestStatusV1.PENDING, isRequired: !isInRefusal, t: t })))),
            react_1.default.createElement(ux_1.Collapse, { in: hasPriceChanged },
                react_1.default.createElement(ux_1.Grid, { item: true, xs: true },
                    react_1.default.createElement(ux_1.Box, { ml: 5, mr: 4 },
                        react_1.default.createElement(react_hook_form_1.Controller, { control: control, defaultValue: false, name: 'shouldAdjustLanePricing', render: ({ field: { onChange, value } }) => {
                                return (react_1.default.createElement(ux_1.FormControlLabel, { control: react_1.default.createElement(ux_1.Checkbox, { color: "primaryLight", onChange: onChange, value: value }), label: react_1.default.createElement(ux_1.Box, { ml: 1 }, t('common:updateLanePricing')) }));
                            } })))),
            react_1.default.createElement(ux_1.Collapse, { in: areActionsShown, timeout: 0 },
                react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(ux_1.Grid, { item: true },
                        hasCarrierTimerExpired && !hasShipperTimeExpired && (react_1.default.createElement(react_1.default.Fragment, null,
                            react_1.default.createElement(ux_1.Box, { className: classes.carrierExpiredContainer, m: 3, p: 3 },
                                react_1.default.createElement(ux_1.Typography, { color: "primaryLight", style: { maxWidth: '80%' }, variant: "bodyMedium" },
                                    react_1.default.createElement(next_i18next_1.Trans, { i18nKey: 'common:carrierTimerExpired' },
                                        react_1.default.createElement(ux_1.StandardButton, { className: classes.carrierExpiredButton, onClick: handleToggleExpirationDetails }, t('common:carrierTimeframe')))),
                                react_1.default.createElement(ux_1.Collapse, { in: areExpirationDetailsShown, timeout: 0 },
                                    react_1.default.createElement(ux_1.Box, { mt: 3 },
                                        react_1.default.createElement(ux_1.Typography, null, t('common:carrierExpirationDate', { date: quoteExpirationDate }))))))),
                        react_1.default.createElement(ux_1.Box, { mt: 5, pl: 4, pr: 4 },
                            react_1.default.createElement(ux_1.Grid, { container: true, justifyContent: "space-between" },
                                isEditable && (react_1.default.createElement(ux_1.Grid, { container: quoteData.carrierQuoteStatus !== schemas_1.EShipmentQuoteRequestStatusV1.PENDING ||
                                        isInRefusal, item: true, justifyContent: quoteData.carrierQuoteStatus === schemas_1.EShipmentQuoteRequestStatusV1.PENDING ||
                                        isInRefusal
                                        ? 'flex-start'
                                        : 'flex-end', xs: true },
                                    react_1.default.createElement(ux_1.FlatButton, { "data-testid": 'QuoteRequestCarrierDetails-NegationButton', disabled: isSaving, onClick: handleNegationButton }, negationButtonText))),
                                (isEditable || isInRefusal) && (react_1.default.createElement(ux_1.Grid, { container: true, item: true, justifyContent: "flex-end", xs: true },
                                    react_1.default.createElement(ux_1.Grid, { item: true },
                                        react_1.default.createElement(__1.SaveButton, { buttonText: confirmationButtonText, color: "primaryLight", customAction: handleConfirmationButton, isDisabled: isSaveButtonDisabled, isSaving: isSaving, t: t, testId: `QuoteRequestCarrierDetails` }))))))))),
            react_1.default.createElement("input", { name: "formAction", type: "hidden", value: schemas_1.EShipmentQuoteRequestStatusV1.ACCEPTED }))));
};
exports.QuoteRequestCarrierResponseDetails = QuoteRequestCarrierResponseDetails;
